/************************************************************
 *
 * Helpers
 *
 */

window.Helpers = {

    isMobile: {
        Android: function () {
            return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function () {
            return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function () {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function () {
            return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function () {
            return navigator.userAgent.match(/IEMobile/i);
        },
        any: function () {
            return (this.Android() || this.BlackBerry() || this.iOS() || this.Opera() || this.Windows());
        }
    },

    // If we detect mobile safari we can take the 44px browser bar into account...
    isMobileSafari: function(){
        var isIphone = /(iPhone)/i.test(navigator.userAgent);
        var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);

        if (isIphone && isSafari){
            return true;
        }
        return false;
    },

    // Returns true if user is on a mobile phone
    mobilecheck: function () {
        return this.isMobile.any();
    },

    prepPhoneNum: function (phone) {
        if (phone !== undefined)
            return phone.replace(/[^+0-9]/g, "");
        return '';
    },

    urlStripQueryHash: function(url) {
        return url.split("?")[0].split("#")[0];
    },

    getCurrentUrlStripQuery: function () {
        return this.urlStripQueryHash(document.URL);
    },

    // ?example=1 ==> window.Helpers.getUrlParamByName('example') == 1
    getUrlParamByName: function (sParam) {
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split('&');
        for (var i = 0; i < sURLVariables.length; i++) {
            var sParameterName = sURLVariables[i].split('=');
            if (sParameterName[0] == sParam) {
                return sParameterName[1];
            }
        }
        return '';
    },

    scrollTo: function (selector, offset, options) {

        var opts = {
                animTime    : 600,  // in ms, without unit
                easing      : 'easeInOutCubic', // NOTE: jquery.easing plugin must be loaded for this to work
                callback    : function(){}
            },
            $win    = $(window);

        if ($(selector).length) {

            options = options == undefined ? false : options;

            if (typeof options == "object") {
                opts = $.extend(opts, options);
            }else if (typeof options == "function") {
                // legacy support
                opts.callback = options;
            }

            var margin      = 0,
                _offset     = offset !== undefined ? offset : -130,
                scrollPos   = $(selector).offset().top + _offset - margin + 1,
                avail       = $(document).height() - $win.height();

            scrollPos = (scrollPos === 0) ? 1 : scrollPos;

            if (scrollPos > avail) {
                scrollPos = avail;
            }

            var time = Math.ceil(Math.abs(scrollPos - $win.scrollTop())) / 4 + opts.animTime;

            this.scrollToPos(scrollPos, time, opts.callback, opts.easing);
        }
    },

    scrollToPos: function (pos, time, callback, easing) {

        easing = easing || false;
        var arrived = false;

        // If easing doesn't exist, disable it
        if (easing == undefined || (easing != undefined && jQuery.easing[easing] == undefined)) {
            easing = false;
        }

        if (pos < 0)
            pos = 0;

        if (pos >= 0) {
            $('body')
                .stop()
                .data('scrolling', true);

            if (easing) {
                $("html, body").animate({
                    scrollTop: pos
                }, time, easing, function () {
                    if (!arrived) {
                        $('body').data('scrolling', false);
                        if (callback !== undefined) {
                            callback();
                        }
                        arrived = true;
                    }
                });
            }else {
                $("html, body").animate({
                    scrollTop: pos
                }, time, function () {
                    if (!arrived) {
                        $('body').data('scrolling', false);
                        if (callback !== undefined) {
                            callback();
                        }
                        arrived = true;
                    }
                });
            }
            return;
        }
    },

    getScrollBarWidth: function() {
        var inner = document.createElement('p');
        inner.style.width = "100%";
        inner.style.height = "200px";

        var outer = document.createElement('div');
        outer.style.position = "absolute";
        outer.style.top = "0px";
        outer.style.left = "0px";
        outer.style.visibility = "hidden";
        outer.style.width = "200px";
        outer.style.height = "150px";
        outer.style.overflow = "hidden";
        outer.appendChild (inner);

        document.body.appendChild (outer);
        var w1 = inner.offsetWidth;
        outer.style.overflow = 'scroll';
        var w2 = inner.offsetWidth;
        if (w1 == w2) w2 = outer.clientWidth;

        document.body.removeChild (outer);

        return (w1 - w2);
    },

    isMicrosoftUser: function () {
        if (/MSIE 10/i.test(navigator.userAgent)) {
            // this is internet explorer 10
            return true;
        }

        if (/MSIE 9/i.test(navigator.userAgent) || /rv:11.0/i.test(navigator.userAgent)) {
            // this is internet explorer 9 and 11
            return true;
        }

        if (/Edge\/12./i.test(navigator.userAgent)) {
            // this is Microsoft Edge
            return true;
        }
        return false;
    },

    isIE: function () {

        var ua = window.navigator.userAgent;
        var msie = ua.indexOf("MSIE ");

        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))      // If Internet Explorer, return version number
            return true;//alert(parseInt(ua.substring(msie + 5, ua.indexOf(".", msie))));
        else                 // If another browser, return 0
            return false;

        return false;
    },

    getInternetExplorerVersion: function () {
        var rv = -1,
            ua,
            re;
        if (navigator.appName == 'Microsoft Internet Explorer') {
            ua = navigator.userAgent;
            re = new RegExp("MSIE ([0-9]{1,}[\.0-9]{0,})");
            if (re.exec(ua) !== null)
                rv = parseFloat(RegExp.$1);
        }
        else if (navigator.appName == 'Netscape') {
            ua = navigator.userAgent;
            re = new RegExp("Trident/.*rv:([0-9]{1,}[\.0-9]{0,})");
            if (re.exec(ua) !== null)
                rv = parseFloat(RegExp.$1);
        }
        return rv;
    }
};