declare var google: any;
declare var MarkerClusterer: any;
declare var ClusterIcon: any;

//////These are created at FindOptician/Index.html
declare var opticianCammpaigns: any[];
declare var authorizedSellerOfLabel: string;
declare var hereYouCanFindLabel: string;
declare var openingHoursLabel: string;
declare var phoneLabel: string;
declare var contactLabel: string;
declare var hereYouCanFindHoverText: string;
declare var languageSpecificCountries: string[];
declare var language: string;
declare var preFilterCampaignFilter: string;
declare var limitNumberOfOpticians: number;
//////////////////////////////////////////////////

export class SeikoFindOptician {
    private window: any = window;
    private hitList: JQuery = $('.hit-list');
    private body: JQuery = $('body');
    private maxHits = 2000;
    private markers: any[];
    private premiumClass = "premium";

    public init() {
        var self: any = this;
        self.apiUrl2 = "/api/optician/search/";
        self.mapIcon = "/Static/SeikoVision/img/Googlemaps/Pin.png";
        self.mapIconHover = "/Static/SeikoVision/img/Googlemaps/Pin_Selected.png";
        self.mapIconPremium = "/Static/SeikoVision/img/Googlemaps/Pin_Premium.png";
        self.mapIconHoverPremium = "/Static/SeikoVision/img/Googlemaps/Pin_Premium_Selected.png";

        self.clusterIcons = "/Static/SeikoVision/img/Googlemaps/m";
        self.markers = [];
        self.lastFocusedMarker;
        self.locations = [];
        self.markerCluster = {};
        self.infowindow = {};
        self.opticianPressed = false;
        self.opticianPressedId = 0;
        self.map = {};
		self.autocompleteService = {};
		self.placesService = {};

        self.searchButton = $('#search-button');
        self.searchInput = $('#searchTextField');
        self.searchWrapper = $('.searchTextField-wrapper');
        self.myLocationWrapper = $('.my-location');
        self.myLocationButton = $('#my-location__button');
        self.myLocationLabel = $("#searchTextField").data("my-location-label");
        self.locationOriginalLabel = self.searchInput[0].placeholder;


        self.lastSearchedPlace;
        self.filters = $('.filter input');
        self.queryFilter = this.window.Helpers.getUrlParamByName('filter');
        self.debug = this.window.Helpers.getUrlParamByName('debug') === 'true';

        // for tracking
        self.opticianTel = $('.optician-tel');

        var myLong = $(".seiko-map-wrapper").data("my-long");
        var myLat = $(".seiko-map-wrapper").data("my-lat");
        var zoom = $(".seiko-map-wrapper").data("zoom");

        var myPos = {
            lng: parseFloat(myLong) || 4.8285748,
            lat: parseFloat(myLat) || 52.3746961
        };

        self.mapOpt = {
            zoom: zoom,
            center: myPos,
            mapTypeControl: false,
            minZoom: 5,
            scrollwheel: false,
            fullscreenControl: false,
            streetViewControl: false,
            maxZoom: 22,
            styles: [
                {
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#f5f5f5"
                        }
                    ]
                },
                {
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#616161"
                        }
                    ]
                },
                {
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "color": "#f5f5f5"
                        }
                    ]
                },
                {
                    "featureType": "administrative.land_parcel",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#bdbdbd"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#eeeeee"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#757575"
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#e5e5e5"
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#9e9e9e"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#757575"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#dadada"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#616161"
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#9e9e9e"
                        }
                    ]
                },
                {
                    "featureType": "transit.line",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#e5e5e5"
                        }
                    ]
                },
                {
                    "featureType": "transit.station",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#eeeeee"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#c9c9c9"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#9e9e9e"
                        }
                    ]
                }
            ]
        };

        if (limitNumberOfOpticians != 0) {
            this.maxHits = limitNumberOfOpticians;
            self.maxHits = limitNumberOfOpticians;
        }

        var json = {
            latitude: myPos.lat,
            longitude: myPos.lng,
            radius: 30,
            hits: self.maxHits,
            filter: 'all',
            page: 0,
            language: language,
            languageSpecificCountries: languageSpecificCountries
        };

        if (preFilterCampaignFilter) {
            json.filter = preFilterCampaignFilter;
        }

        if (self.queryFilter) {
            json.filter = self.queryFilter;
        }

        self.searchInput[0].addEventListener('focus', function (event) {
            self.myLocationWrapper[0].classList.remove("is-hidden");
        });

        $(document).on('click', function () {
            if (self.searchInput[0] !== $(':focus')[0]) {
                self.myLocationWrapper[0].classList.add("is-hidden");
            }
        });

        self.myLocationButton[0].addEventListener("click", function () {
            navigator.geolocation.getCurrentPosition(function (position: any) {
                let {
                    latitude,
                    longitude
                } = position.coords;
                myPos.lng = longitude;
                myPos.lat = latitude;
                self.map.setCenter(myPos);
                self.map.setZoom(10); // Why 10? Because it looks good.
                self.searchInput[0].placeholder = self.myLocationLabel;
                self.searchInput[0].value = '';
                self.myLocationWrapper[0].classList.add("is-hidden");
                google.maps.event.addListener(self.map,
                    'center_changed',
                    function () {
                        self.searchInput[0].placeholder = self.locationOriginalLabel;
                    });
                window.dataLayer.push({
                    'event': 'findOptician',
                    'type': 'my location'
                });
            });
        });

        self.infowindow = new google.maps.InfoWindow();

        google.maps.event.addListener(self.infowindow,
            'closeclick',
            function () {
                if (self.lastFocusedMarker) {
                    self.lastFocusedMarker.setIcon(self.lastFocusedMarker.defaultIcon);
                }
                $(".optician.active").removeClass("active");
                $(".optician.inactive").removeClass("inactive");
                self.lastFocusedMarker = undefined;
            });

        self.map = new google.maps.Map(document.getElementById('map'), self.mapOpt);

		self.autocompleteService = new google.maps.places.AutocompleteService();
		self.placesService = new google.maps.places.PlacesService(self.map);

        self.clusterStyles = [
            {
                url: '/Static/SeikoVision/img/GoogleMaps/m1.png',
                textColor: 'black',
                height: 60,
                width: 28,
                anchor: [8, 0]
            },
            {
                url: '/Static/SeikoVision/img/GoogleMaps/m2.png',
                textColor: 'black',
                height: 60,
                width: 28,
                anchor: [8, 0]
            },
            {
                url: '/Static/SeikoVision/img/GoogleMaps/m3.png',
                textColor: 'black',
                height: 60,
                width: 28,
                anchor: [8, 0]
            },
            {
                url: '/Static/SeikoVision/img/GoogleMaps/m4.png',
                textColor: 'black',
                height: 60,
                width: 28,
                anchor: [8, 0]
            },
            {
                url: '/Static/SeikoVision/img/GoogleMaps/m5.png',
                textColor: 'black',
                height: 60,
                width: 28,
                anchor: [8, 0]
            }


        ];

        self.markerCluster = new MarkerClusterer(self.map,
            [],
            { imagePath: self.clusterIcons, gridSize: 40, styles: self.clusterStyles, maxZoom: 12 });

        ClusterIcon.prototype.triggerClusterClick = function () {
            var markerClusterer = this.cluster_.getMarkerClusterer();

            google.maps.event.trigger(markerClusterer, 'clusterclick', this.cluster_);

            if (markerClusterer.isZoomOnClick()) {
                // Zoom into the cluster.
                this.map_.fitBounds(this.cluster_.getBounds());

                // modified zoom in function
                this.map_.setZoom(self.map.getZoom() - 1);

            }
        };

        google.maps.event.addListener(self.map,
            'idle',
            function () {
                self.boundsChanged(json);
            });

        var marker = new google.maps.Marker({
            map: self.map,
            anchorPoint: new google.maps.Point(0, -29)
        });

        self.hitList.on('click', '.optician', (e) => { this.ClickedOpticianInList(e) });

        this.body.on("click",
            ".close-button-container",
            () => {
                self.infowindow.close();
                $(".optician.active").removeClass("active");
                $(".optician.inactive").removeClass("inactive");

            }
        );

        this.body.on("click",
            ".optician.active a:not(.close-button-container)",
            (e) => {
                let contactMode;
                e.preventDefault();

                const element = e.target.parentNode;
                const parent = element.parentNode;
                const url = e.target.closest("a");

                if (element.classList.contains('optician-tel')) {
                    contactMode = "telephone";
                }

                if (url.classList.contains('optician-websiteurl')) {
                    contactMode = "website";
                }

                if (!url) return;
                // DM fields
                var child = document.querySelector('.optician.active');
                var listNode = child.parentNode;
                var position = Array.prototype.indexOf.call(listNode.children, child);
                var name = (parent.querySelector('.optician-name') as HTMLElement).innerText;
                var adress = (parent.querySelector('.optician-address') as HTMLElement).innerText;
                var adress = (parent.querySelector('.optician-address') as HTMLElement).innerText;

                window.dataLayer.push({
                    'event': 'findOptician',
                    'type': 'contact optician',
                    'optician': {
                        'position': position,
                        'contactMethod': contactMode,
                        'name': name,
                        'address': adress
                    }
                });
                setTimeout(function () { location.href = url }, 1000);
            });

        self.filters.on('change',
            function () {
                json.filter = $(this).val() as string;
                self.plotMarkers(json);
                const filterValue = this.nextElementSibling.innerText;
                window.history.pushState("", "", "?filter=" + json.filter);
                window.dataLayer.push({
                    'event': 'findOptician',
                    'type': 'filter',
                    'filterValue': filterValue
                });
            });

        if (navigator.userAgent.match(/(iPad|iPhone|iPod)/g)) {
            setTimeout(function () {
                var container = document.getElementsByClassName('pac-container')[0];
                container.addEventListener('touchend',
                    function (e) {
                        e.stopImmediatePropagation();
                    });
            },
                500);
        }

        $(document).on({
            'DOMNodeInserted': function () {
                $('.pac-item, .pac-item span', this).addClass('needsclick');
            }
        },
            '.pac-container');
        return self;
        //navigator.geolocation.getCurrentPosition(function (position) {
        //});


    }


    private ClickedOpticianInList(event) {
        if (!$(event.currentTarget).hasClass("active")) {
            this.body.animate({ scrollTop: 0 }, 'slow');
            var id = $(event.currentTarget).data('id');
            this.indicateInList(event, this.markers.find(x => x != undefined && x.id === id));
            this.indicateOnMap(id);


            // DM fields
            var child = document.querySelector('.optician.active');
            var parent = child.parentNode;
            var position = Array.prototype.indexOf.call(parent.children, child);

            var name = (child.querySelector('.optician-name') as HTMLElement).innerText;
            var adress = (child.querySelector('.optician-address') as HTMLElement).innerText;
            var adress = (child.querySelector('.optician-address') as HTMLElement).innerText;

            window.dataLayer.push({
                'event': 'findOptician',
                'type': 'Click on results',
                'optician': {
                    'position': position,
                    'name': name,
                    'address': adress
                }
            });

        }
    }


    ////////////////////////////////////////////////////////
    /// Called upon when user moves map or ure use the zoom
    /////////////////////////////////////////////////////////
    public boundsChanged(json) {
        var self: any = this;

        if (!self.searchBox) {
            self.initSearchBox();
        }

        var bounds = self.map.getBounds();
        var center = self.map.getCenter();

        if (bounds && center) {
            var ne = bounds.getNorthEast();

            // Calculate radius from center to top right corner of map bounds (in meters).
            var radius = google.maps.geometry.spherical.computeDistanceBetween(center, { lng: ne.lng(), lat: ne.lat() });

            // Get distance in km
            var kmRadius = radius / 1000;

            json.latitude = center.lat;
            json.longitude = center.lng;
            json.radius = kmRadius;
            this.plotMarkers(json);
        }
    }

    public initSearchBox() {
        var self: any = this;

        //var marker = new google.maps.Marker({
        //    map: self.map,
        //    anchorPoint: new google.maps.Point(0, -29)
        //});

        self.searchBox = new google.maps.places.Autocomplete(self.searchInput[0]);
        self.searchBox.bindTo('bounds', self.map);
        self.searchBox.setTypes(['geocode']);

        //self.searchButton.on('click', function () {
        //    //self.searchBox.trigger('places_changed');
        //    var e = $.Event("places_changed");
        //    //e.which = 13; // Enter key press
        //    self.searchInput.trigger(e);
        //});

        self.searchBox.addListener('place_changed',
            function () {
                self.infowindow.close();
                self.lastSearchedPlace = self.searchBox.getPlace();
                self.centerOnSearchedPlace();
                self.myLocationWrapper[0].classList.add("is-hidden");

                window.dataLayer.push({
                    'event': 'findOptician',
                    'type': 'Search bar',
                    'searchedTerm': self.searchBox.getPlace().formatted_address
                });
            });

        self.searchInput.on('keyup',
            function (e) {
                if (e.keyCode == 13) {
					self.onSubmit();
                } else {
                    self.lastSearchedPlace = undefined;
                }
            });

        self.searchInput.on('focusout',
            function () {
                self.onSubmit();
            });

        self.searchButton.on('click',
            function () {
				self.onSubmit();
            });
    }

    public centerOnSearchedPlace() {
        var self: any = this;

        if (!self.lastSearchedPlace || !self.lastSearchedPlace.geometry) {
            return;
        }

        var geometry = self.lastSearchedPlace.geometry;

        // If the place has a geometry, then present it on a map.
        if (geometry.viewport) {
            self.map.fitBounds(geometry.viewport);
        } else {
            self.map.setCenter(geometry.location);
            self.map.setZoom(17); // Why 17? Because it looks good.
        }
        //marker.setPosition(place.geometry.location);
        if (self.debug) {
            console.log('debugging');
        }
    }

	public onSubmit() {
		var self: any = this;

		if ((!self.lastSearchedPlace || !self.lastSearchedPlace.geometry) && self.searchInput[0].value) {
			// Logic to get place based on prediction for entered text
			self.autocompleteService.getPlacePredictions(
				{ input: self.searchInput[0].value, types: ['geocode'] },
				(predictions, status)=> {
					if (status !== google.maps.places.PlacesServiceStatus.OK || !predictions) {
						return
					}
					// We are taking only predictions[0] as first item in list
					self.placesService.getDetails(
						{ placeId: predictions[0].place_id },
						(place) => {
							self.searchInput[0].value  = predictions[0].description
							self.lastSearchedPlace = place;
							self.centerOnSearchedPlace();
						}
					)
				}
			)
		}
	}

    ////////////////////////////////////////////////////////
    /// Calls API, transforms result into markers and plot them on map, clustered.
    /////////////////////////////////////////////////////////
    public plotMarkers(json) {
        var self: any = this;

        $.post(self.apiUrl2,
            json,
            function (apiDto) {

                var newMarkers = [];
                var indicateMarker;

                $.each(apiDto.result,
                    function (index, dealer: Optician) {
                        var position = { lat: dealer.latitude, lng: dealer.longitude };

                        if (!self.map.getBounds().contains(position)) {
                            return true; // Continue to next iteration
                        }
                        var mark = new google.maps.Marker({
                            id: dealer.id,
                            map: self.map,
                            position: position,
                            icon: self.mapIcon,
                            defaultIcon: self.mapIcon,
                            iconHover: self.mapIconHover,
                            title: dealer.name,
                            name: dealer.name,
                            openingHours1: dealer.openingHours1,
                            openingHours2: dealer.openingHours2,
                            openingHours3: dealer.openingHours3,
                            country: dealer.country,
                            streetAdress: dealer.streetAdress,
                            zipCode: dealer.zipCode,
                            city: dealer.city,
                            state: dealer.state,
                            seikoNetwork: dealer.seikoNetwork,
                            campaigns: dealer.campaigns,
                            websiteUrl: dealer.websiteUrl,
                            isPremium: dealer.isPremium,
                            showContactForm: dealer.showContactForm,
                            phoneNumber: dealer.phoneNumber || "",
                            email: dealer.email || "",
                            pixelOffset: new google.maps.Size(100, 140)
                        });

                        if (dealer.isPremium || dealer.seikoNetwork === 'Seiko Vision Specialist' || dealer.campaigns.indexOf('svs') !== -1) {
                            mark.defaultIcon = self.mapIconPremium;
                            mark.iconHover = self.mapIconHoverPremium;
                            mark.icon = self.mapIconPremium;
                        }





                        if (self.opticianPressed && dealer.id === self.opticianPressedId) {
                            indicateMarker = mark;
                        }

                        if (self.lastFocusedMarker && self.lastFocusedMarker.id === dealer.id) {
                            indicateMarker = mark;
                        }

                        newMarkers[index] = mark;
                        var timer;
                        var fn = function (e) {
                            if (self.lastFocusedMarker) {
                                self.lastFocusedMarker.setIcon(self.lastFocusedMarker.defaultIcon);
                            }

                            mark.setIcon(mark.iconHover);
                            self.updatePopup(mark, 'Mouse Over');
                            self.infowindow.open(self.map, mark);
                            self.indicateInList(e, mark);
                            self.lastFocusedMarker = mark;
                        };
                        google.maps.event.addListener(mark, 'click', fn);
                        google.maps.event.addListener(mark, 'Mouse Over', fn);


                        google.maps.event.addListener(mark, 'mouseover', function (e) {

                            timer = setTimeout(function () {
                                if (self.lastFocusedMarker) {
                                    self.lastFocusedMarker.setIcon(self.lastFocusedMarker.defaultIcon);
                                }

                                mark.setIcon(mark.iconHover);
                                self.updatePopup(mark, 'Mouse Over');
                                self.infowindow.open(self.map, mark);
                                self.indicateInList(e, mark);
                                self.lastFocusedMarker = mark;
                            },
                                300);
                        });
                        google.maps.event.addListener(mark, 'mouseout', function () {
                            // on mouse out, cancel the timer
                            clearTimeout(timer);
                        });


                    });

                self.markerCluster.clearMarkers();

                // Remove old markers
                if (self.markers && self.markers.length > 0) {
                    for (var i = 0; i < self.markers.length; i++) {
                        if (self.markers[i]) {
                            self.markers[i].setMap(null);
                        }
                    }
                }

                self.markers = newMarkers;

                self.markerCluster.addMarkers(self.markers, false);

                //Have we just pressed on a optician in the list?
                if (indicateMarker) {
                    indicateMarker.icon = indicateMarker.iconHover;
                    self.updatePopup(indicateMarker, 'Mouse Over');
                    self.infowindow.open(self.map, indicateMarker);
                }

                //Update the scroll list
                self.updateList();
                if (self.opticianPressed) {
                    self.indicateInList(self.opticianPressedId, false);
                    //self.opticianPressed = false;
                }
            });
    }

    /**Updates the list of opticians */
    public updateList() {
        var resultText = $('.resultText');
        var numOfHits = $('.numOfHits', resultText);
        this.hitList.empty();

        var count = 0;
        this.markers.forEach(marker => {
            if (marker != undefined) {
                count++;
                this.hitList.append(this.getListOpticianHtml(marker));
            }
        });

        if (count > this.maxHits) {
            resultText.hide();
        } else {
            numOfHits.empty().append(<string><any>count);
            resultText.show();

            window.dataLayer.push({
                'event': 'findOptician',
                'type': 'Update Result List',
                'results': count
            });
        }
    }

    /** Indicate in the list of Opticians that we clicked on the map. */
    public indicateInList(e, optician: Optician) {
        if (!optician) {
            return;
        }

        var self: any = this;

        $('.optician.active').removeClass('active');

        var itemToIndicate = $('.optician[data-id="' + optician.id + '"]');

        if (!itemToIndicate || itemToIndicate.length === 0) {
            console.log('Could not find optician in list');
            return;
        }

        itemToIndicate.replaceWith(this.getFullOpticianHtml(optician));

        $('.optician:not(.active)').addClass('inactive');
        e.preventDefault;

        // Element position is always 193px more than actual position
        var newPosition = self.hitList.scrollTop() + (itemToIndicate.position().top - 193);

        self.hitList.animate({
            scrollTop: newPosition
        });
    }

    public findPos(_el) {
        var curleft = 0;
        var curtop = 0;
        var curtopscroll = 0;
        var curleftscroll = 0;

        if (_el.offsetParent) {
            curleft = _el.offsetLeft;
            curtop = _el.offsetTop;

            /* get element scroll position */
            var elScroll = _el;
            while (elScroll = elScroll.parentNode) {
                curtopscroll = elScroll.scrollTop ? elScroll.scrollTop : 0;
                curleftscroll = elScroll.scrollLeft ? elScroll.scrollLeft : 0;

                curleft -= curleftscroll;
                curtop -= curtopscroll;
            }

            /* get element offset postion */
            while (_el = _el.offsetParent) {
                curleft += _el.offsetLeft;
                curtop += _el.offsetTop;
            }
        }

        var isIE = navigator.appName.indexOf('Microsoft Internet Explorer') != -1;

        /* get window scroll position */
        var offsetX = isIE ? document.body.scrollLeft : window.pageXOffset;
        var offsetY = isIE ? document.body.scrollTop : window.pageYOffset;

        return [curtop + offsetY, curleft + offsetX];
    }

    public indicateOnMap(id) {
        var self: any = this;
        var markers = self.markers;
        var marker = $.grep(markers,
            function (e: any) {
                return e !== undefined && e.id === id;
            });

        self.opticianPressed = true;
        self.opticianPressedId = marker[0].id;

        var pos = marker[0].getPosition();

        // if the marker is in a cluster, it will not be attached to the map and we need to manually position infowindow
        if (!marker[0].getMap()) {

            self.updatePopup(marker[0], 'Click');
            self.infowindow.setOptions({
                position: { lng: pos.lng(), lat: pos.lat() },
                pixelOffset: { height: -20, width: 0 }
            });
            self.infowindow.open(self.map, null);

        } else {
            //self.map.setZoom(16);
            // Mulitply with 0.001 to give extra space for marker popup
            //self.map.panTo({ lat: pos.lat() + 0.001, lng: pos.lng() });

            marker[0].icon = marker[0].iconHover;
            self.updatePopup(marker[0], 'Click');

            self.infowindow.setOptions({ pixelOffset: { height: 0, width: 0 } });

            self.infowindow.open(self.map, marker[0]);
        }
    }

    public updatePopup(marker, eventType) {
        var self: any = this;
        var popupHtml = this.getPopupHtml(marker, eventType);
        self.infowindow.setContent(popupHtml);
    }

    public updateOptician(marker: Optician) {
        var self: any = this;
        self.infowindow.setContent(this.getListOpticianHtml(marker));

    }

    private getPopupHtml(optician: Optician, eventType) {
        let opticianHtml = `<div class="popup"><span class="optician-name">${optician.name}</span>`;

        if (eventType === 'Mouse Over') {
            window.dataLayer.push({
                'event': 'findOptician',
                'eventType': eventType,
                'optician': {
                    'name': optician.name,
                    'address': optician.streetAdress,
                    'country': optician.country
                }
            });
        };

        opticianHtml += this.getAddressHtml(optician);
        opticianHtml += `<span class="optician-tel"><a href="tel:${optician.phoneNumber}">${optician.phoneNumber
            }</a></span></div>`;
        return opticianHtml;
    }

    private getListOpticianHtml(optician: Optician) {
        let opticianHtml = `<div class="optician ${optician.isPremium || optician.seikoNetwork === 'Seiko Vision Specialist' || optician.campaigns.indexOf('svs') !== -1 ? this.premiumClass : ""}" data-id="${optician.id}">
                            <span class="optician-name">${optician.name}</span>`;
        opticianHtml += this.getAddressHtml(optician);
        opticianHtml += `</div>`;

        return opticianHtml;
    }

    private getFullOpticianHtml(optician: Optician) {
        console.log("getFullOpticianHtml")

        let opticianHtml = `<div class="optician active ${optician.isPremium || optician.seikoNetwork === 'Seiko Vision Specialist' || optician.campaigns.indexOf('svs') !== -1 ? this.premiumClass : ""}" data-id="${optician.id}">
                            <a class="close-button-container" href="#"><span class="close-icon"></span></a>
                            <span class="optician-name ${optician.isPremium || optician.seikoNetwork === 'Seiko Vision Specialist' || optician.campaigns.indexOf('svs') !== -1 ? this.premiumClass : ""}">${optician.name}</span>`;
        opticianHtml += this.getAddressHtml(optician);

        if (optician.openingHours1 !== "" || optician.openingHours2 !== "" || optician.openingHours3 !== "") {
            opticianHtml += `<div class="optician-openingHours">
                                <span class="optician-openingHours-label">${openingHoursLabel}</span>
                                <span>${optician.openingHours1}</span>
                                <span>${optician.openingHours2}</span>
                                <span>${optician.openingHours3}</span>
                            </div>`
        }

        if (optician.phoneNumber !== "") {
            var strPhoneLabel = "";
            if (phoneLabel.length > 0)
                strPhoneLabel = phoneLabel + ":";

            opticianHtml += `<span class="optician-tel">${strPhoneLabel}<a href="tel:${optician.phoneNumber}">${optician
                .phoneNumber}</a></span>`
        }
        if (optician.websiteUrl !== "") {
            opticianHtml += `<a class="optician-websiteurl" href = "http://${optician.websiteUrl}" target = "_blank" > ${optician.websiteUrl} </a>`
        }

        opticianHtml += this.getHereWeSellInfo(optician);
        opticianHtml += this.getAuthorizedSellerInfo(optician);
        opticianHtml += '</div>';

        return opticianHtml;
    }

    private getAddressHtml(optician: Optician): string {
        let addressHtml = "";
        if (optician.country === "United States") {
            addressHtml += `<span class="optician-address" >${optician.streetAdress}<br>${optician.city} ${optician.state} ${optician.zipCode}</span>`;
        } else {
            let spacer = "";
            if (optician.zipCode !== "") {
                spacer = ", ";
            }
            addressHtml += `<span class="optician-address" >${optician.streetAdress}<br>${optician.zipCode}${spacer}${optician.city}</span>`;
        }
        return addressHtml;
    }

    private getHereWeSellInfo(dealer: Optician): string {
        var showSellerInfo = false;
        var dealerCampaigns = (dealer.campaigns || "").split("|");
        let campaignHtml = dealer.campaigns ? `<div class="optician-campaign-info"><span class="campaign-label">${hereYouCanFindLabel}</span>` : "";
        dealerCampaigns.forEach(function (opticianCampaign) {
            if (opticianCampaign.length > 0) {
                opticianCammpaigns.forEach(function (campaign: CampaignFilter) {
                    if (campaign.ShowAsSellerInfo && campaign.Id === opticianCampaign) {
                        showSellerInfo = true;
                        campaignHtml += `<div class="tooltip">
                                         <img class="seller-icon" src="${campaign.IconUrl}"></img>
                                         <span class="tooltiptext">${hereYouCanFindHoverText} ${campaign.Name}</span>
                                         </div>`;
                    }
                });
            }

        });



        return showSellerInfo ? campaignHtml + "</div>" : "";
    }

    private getAuthorizedSellerInfo(optician: Optician) {
        var info = "";
        var dealerCampaigns = (optician.campaigns || "").split("|");
        info = optician.campaigns ? `<div class="optician-seller-info">` : "";
        dealerCampaigns.forEach(function (opticianCampaign) {
            if (opticianCampaign.length > 0) {
                opticianCammpaigns.forEach(function (campaign) {
                    if (campaign.ShowAuthorizedSellers && campaign.Id === opticianCampaign) {
                        info += "<span class=\"seller-info\">" +
                            authorizedSellerOfLabel.replace("{CAMPAIGN_NAME}", campaign.Name) +
                            "</span>";
                    }
                });
            }

        });

        return info != "" ? info + "</div>" : "";
    }


    private sendDataLayer(event: string, eventType: string,) {
        window.dataLayer.push({
            'event': event,
            'type': eventType,

        })
    }
}

interface Optician {
    id: string;
    name: string;
    streetAdress: string;
    zipCode: string;
    city: string;
    state: string;
    country: string;
    latitude: number;
    longitude: number;

    openingHours1: string;
    openingHours2: string;
    openingHours3: string;
    email: string;
    phoneNumber: string;
    websiteUrl: string;
    sellerInfo: string;
    //Filter
    campaigns: string;
    sellsYuniko: boolean;
    isHoyaCenter: boolean;
    isPremium: boolean;
    seikoNetwork: string;
    showContactForm: boolean;

}

interface CampaignFilter {
    Id: string;
    Name: string;
    Active: string;
    ShowAsSellerInfo: boolean;
    ShowAuthorizedSellers: boolean;
    IconUrl: string;
}