import { SeikoFindOptician } from "./seiko-find-optician";
import { SeikoLoginRegisterPage } from "./seiko-login-register-page";
import Blocks from "./blocks";

declare global {
    interface Array<T> {
        find(predicate: (search: T) => boolean): T;
    }
    interface Window {
        dataLayer: any;
    }
}

export class Application {
    win: any;

    constructor() {
        this.win = window as any;

        if (!Array.prototype.find) {
            Array.prototype.find = function (predicate) {
                if (this == null) {
                    throw new TypeError('Array.prototype.find called on null or undefined');
                }
                if (typeof predicate !== 'function') {
                    throw new TypeError('predicate must be a function');
                }
                var list = Object(this);
                var length = list.length >>> 0;
                var thisArg = arguments[1];
                var value;

                for (var i = 0; i < length; i++) {
                    value = list[i];
                    if (predicate.call(thisArg, value, i, list)) {
                        return value;
                    }
                }
                return undefined;
            };
        };

        this.initCookieBot();

        this.win.initMap = function () {
            new SeikoFindOptician().init();
        }

        var loginPage = new SeikoLoginRegisterPage();
        loginPage.init();
        this.win.Login = loginPage;

        new Blocks();
    }

    initCookieBot() {
        $("body").on("click", ".cookie-settings", () => {
            if (this.win.Cookiebot) {
                this.win.Cookiebot.renew();
            }
        });
    }
}

new Application();