window.Main = {
    opts    : {
        heroInDelay : 350
    },
    resizeTimer: 0, // Used for resizing hero
    init: function () {
        var self        = this,
            $win        = $(window),
            $html       = $('html'),
            $body       = $('body');

        (!("ontouchstart" in document.documentElement)) ? $html.addClass('no-touch') : $html.addClass('touch');
 
        if (window.Helpers.isMobileSafari()) {
            $html.addClass('mobile-safari');
        }

        // Detect all kinds of stupidity
        if (window.Helpers.isMicrosoftUser()) { 
            $html
                .addClass('ie-' + window.Helpers.getInternetExplorerVersion())
                .addClass('ms-user')
                .removeClass('touch').addClass('no-touch'); // can't predict touch screen on windows machines;

            var ieVersion = parseInt(window.Helpers.getInternetExplorerVersion(), 10);

            if (ieVersion != 0 && ieVersion < 11) {
                $html.addClass('lt-ie11');
            }
        }

        // Set transitions class to enable transitions
        $body.addClass('transitions');

        // Disable transitions while resizing
        var resizeTimer;
        $win.on('resize', function(){


            $body.removeClass('transitions');
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(function(){
                $body.addClass('transitions');
            }, 750);
        });
    },
};


/*******************************************
 *
 *  Main initiation calls
 *
 */
$(function () {
    window.Main.init();
    window.SiteSelector.init();
    window.Notifications.init();
});